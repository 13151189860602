/* You can add global styles to this file, and also import other style files */
// body,
// html {
//   scroll-behavior: smooth !important;
//   margin-top: 112px; // header height / header is 80 plus 2rem, 32px
// }

// .ais-SearchBox {
//   margin-bottom: 1em;
// }

:host ::ng-deep app-application-share {
  margin: 0 auto;
}

body {
  overflow: hidden;
}
